import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from 'src/app/app.main.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from 'firebase/auth';
import { getUserRoles } from 'src/app/core/functions';
import { Permission } from 'src/app/core/enums/permissions';
import { SharedUserDataService } from 'src/app/shared/shared-user-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inlinemenu',
  templateUrl: './app.inlinemenu.component.html',
  animations: [
    trigger('inline', [
      state('hidden', style({
        height: '0px',
        overflow: 'hidden'
      })),
      state('visible', style({
        height: '*',
      })),
      state('hiddenAnimated', style({
        height: '0px',
        overflow: 'hidden'
      })),
      state('visibleAnimated', style({
        height: '*',
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppInlineMenuComponent implements OnInit, OnDestroy {
  user: User;
  avatar: string;
  initials: string;
  displayName: string;
  isAdmin: boolean;
  private subsAvatar: Subscription;
  private subsAuth: Subscription;

  constructor(public appMain: AppMainComponent,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    public sharedUserDataService: SharedUserDataService) {
    this.initAvatar();
  }

  initAvatar() {
    const avatar = this.sharedUserDataService.getAvatarValue();
    if (avatar) {
      this.avatar = avatar.url;
      this.initials = avatar.initials;
      this.displayName = avatar.fullName;
    }
  }

  ngOnDestroy(): void {
    this.subsAvatar?.unsubscribe();
    this.subsAuth?.unsubscribe();
  }

  ngOnInit(): void {
    this.subsAuth = this.afAuth.idTokenResult.subscribe((user) => {
      if (user) {
        this.isAdmin = getUserRoles(user.claims).includes(Permission.Admin);
      }
    });
    this.subsAvatar = this.sharedUserDataService.getAvatar()
      .subscribe(avatar => {
        this.avatar = avatar.url;
        this.initials = avatar.initials;
        this.displayName = avatar.fullName;
      })

  }

  logout() {
    this.authService.SignOut();
  }
}
