import { NgModule } from "@angular/core";
import { AvatarComponent } from "./avatar.component";
import { CommonModule, DatePipe } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TooltipModule } from "primeng/tooltip";
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from "primeng/avatar";
@NgModule({
  declarations: [
    AvatarComponent,
  ],
  exports: [AvatarComponent],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    ReactiveFormsModule,
    ButtonModule,
    AvatarModule,
    BadgeModule,
    MenuModule
  ],
  providers: [
    DatePipe
  ],

})

export class CustomAvatarModule { }
