import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { Observable, of } from "rxjs";
import { HttpParamsBuilder } from "src/app/shared/http-params.builder";
import { PaginatedResult } from "src/app/shared/paginated-result";
import { environment } from "src/environments/environment";
import { ChangeVersionModel, VersionListModel, VersionModel } from "./version.model";

@Injectable()
export class VersionsService {
  constructor(private http: HttpClient) { }

  private buildUrl(id?: string): string {
    if (id)
      return `${environment.api}/versions/${id}`
    return `${environment.api}/versions`
  }

  private buildUrlChanges(versionId: string, id?: string): string {
    if (id)
      return `${environment.api}/versions/${versionId}/changes/${id}`
    return `${environment.api}/versions/${versionId}/changes`
  }

  getLastVersions(): Observable<PaginatedResult<VersionListModel>> {
    let params = new HttpParams()
      .set("searchTerm", '')
      .set("orderBy", 'date')
      .set("order", "desc")
      .set("index", 1)
      .set("includeChanges", true)
      .set("size", 10);

    return this.http.get<PaginatedResult<VersionListModel>>(
      this.buildUrl(),
      {
        params: params
      }
    )

  }
  getAll(event: LazyLoadEvent): Observable<PaginatedResult<VersionListModel>> {
    let params = HttpParamsBuilder.fromLazyEvent(event);
    return this.http.get<PaginatedResult<VersionListModel>>(
      this.buildUrl(),
      {
        params: params
      }
    )
  }

  get(id: string): Observable<VersionModel> {
    return this.http.get<VersionModel>(this.buildUrl(id));
  }

  add(version: VersionModel): Observable<VersionModel> {
    return this.http.post<VersionModel>(this.buildUrl(), version)
  }

  update(id: string, version: VersionModel): Observable<VersionModel> {
    return this.http.put<VersionModel>(this.buildUrl(id), version)
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(this.buildUrl(id));
  }

  deleteByArray(ids: string[]): Observable<any> {
    return this.http.delete<any>(this.buildUrl(), { params: HttpParamsBuilder.fromIds(ids) });
  }

  //Changes
  getAllChanges(versionId: string): Observable<ChangeVersionModel[]> {
    return this.http.get<ChangeVersionModel[]>(this.buildUrlChanges(versionId));
  }

  addChange(versionId: string, change: ChangeVersionModel): Observable<ChangeVersionModel> {
    return this.http.post<ChangeVersionModel>(this.buildUrlChanges(versionId), change)
  }

  updateChange(versionId: string, changeId: string, change: ChangeVersionModel): Observable<ChangeVersionModel> {
    return this.http.put<ChangeVersionModel>(this.buildUrlChanges(versionId, changeId), change)
  }

  deleteChange(versionId: string, changeId: string): Observable<any> {
    return this.http.delete<any>(this.buildUrlChanges(versionId, changeId));
  }

  deleteChangesByArray(versionId: string, ids: string[]): Observable<any> {
    return this.http.delete<any>(this.buildUrlChanges(versionId), { params: HttpParamsBuilder.fromIds(ids) });
  }

}
