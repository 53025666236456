import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppComponent } from 'src/app/app.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getUserDefaultProfile, getUserRoles } from 'src/app/core/functions';
import { Permission } from 'src/app/core/enums/permissions';
import { Profile } from 'src/app/core/enums/profile';
import { ProfileService } from 'src/app/shared/profile.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { getCompanyStorage } from 'src/app/core/functions-localStorage';
import { SharedSytleService } from 'src/app/shared/shared-style.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  public model: any[];
  isAuthenticated: boolean;
  profiles: any
  profile: any;
  subsProfile: Subscription;
  subsAuth: Subscription;
  logo: String;
  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    public afAuth: AngularFireAuth,
    public router: Router,
    private permissionsService: NgxPermissionsService,
    public profileService: ProfileService,
    private sharedSytleService: SharedSytleService) { }

  ngOnDestroy(): void {
    this.subsProfile?.unsubscribe();
    this.subsAuth?.unsubscribe();
  }

  ngOnInit() {
    this.logo = this.sharedSytleService.logo;
    this.subsAuth = this.afAuth.idTokenResult.subscribe((user) => {
      if (user) {
        const permissions = getUserRoles(user.claims);
        this.permissionsService.loadPermissions(permissions);
        this.profile = getUserDefaultProfile(user.claims);
        this.subsProfile = this.profileService.getProfile()
          .subscribe(x => {
            this.profile = x;
            this.checkItemMenuProfile(this.profile, this.router.url);
          });
      }
    });



    this.model = [
      {
        label: 'Dashboard', icon: 'pi pi-fw pi-th-large', routerLink: ['/dashboard'],
        roles: [Permission.Admin, Permission.CompanyManager, Permission.WorkplaceManager, Permission.Employee],
        profiles: [Profile.Admin, Profile.CompanyManager, Profile.WorkplaceManager, Profile.Employee],
        show: true
      }, {
        label: 'Guía del trabajador',
        icon: 'pi pi-fw pi-question',
        routerLink: ['/faq'],
        roles: [Permission.Admin, Permission.CompanyManager, Permission.WorkplaceManager, Permission.Employee],
        profiles: [Profile.Admin, Profile.CompanyManager, Profile.WorkplaceManager, Profile.Employee],
        required: ["admincompany"],
        show: true
      }, {
        label: 'Empresa',
        icon: 'pi pi-fw pi-id-card',
        routerLink: ['/company'],
        roles: [Permission.Admin, Permission.CompanyManager],
        profiles: [Profile.Admin, Profile.CompanyManager],
        required: ["admincompany"],
        show: true
      }, {
        label: 'Centros de trabajo',
        icon: 'pi pi-fw pi-building',
        routerLink: ['/workplaces'],
        roles: [Permission.Admin, Permission.CompanyManager],
        profiles: [Profile.Admin, Profile.CompanyManager],
        show: true
      }, {
        label: 'Empleados',
        icon: 'pi pi-fw pi-users',
        routerLink: ['/employees'],
        roles: [Permission.Admin, Permission.CompanyManager, Permission.WorkplaceManager,],
        profiles: [Profile.Admin, Profile.CompanyManager, Profile.WorkplaceManager,],
        show: true,
      }
      , {
        label: 'Eventos',
        iconFamily: 'fontawesome',
        icon: ['fas', 'clipboard'],
        routerLink: ['/registers-company'],
        roles: [Permission.Admin],
        profiles: [Profile.Admin, Profile.CompanyManager],
        show: true,
      }
      , {
        label: 'Fichajes',
        icon: 'pi pi-fw pi-clock',
        routerLink: ['/registers'],
        roles: [Permission.Admin, Permission.CompanyManager, Permission.WorkplaceManager,],
        profiles: [Profile.Admin, Profile.CompanyManager, Profile.WorkplaceManager],
        show: true,
      }
      ,
      {
        label: 'Fichajes',
        icon: 'pi pi-fw pi-clock',
        routerLink: ['/my-registers'],
        roles: [Permission.CompanyManager, Permission.Employee, Permission.WorkplaceManager],
        profiles: [Profile.Employee],
        show: true
      }
      , {
        label: 'Calendario',
        icon: 'pi pi-fw pi-calendar',
        routerLink: ['/my-calendars'],
        roles: [Permission.Employee, Permission.CompanyManager, Permission.WorkplaceManager],
        profiles: [Profile.Employee],
        show: true
      },
      {
        label: 'Solicitudes',
        icon: 'pi pi-fw pi-sync',
        routerLink: ['/my-requests'],
        roles: [Permission.CompanyManager, Permission.Employee, Permission.WorkplaceManager],
        profiles: [Profile.Employee],
        show: true
      }
      , {
        label: 'Solicitudes',
        icon: 'pi pi-fw pi-sync',
        routerLink: ['/requests'],
        roles: [Permission.Admin, Permission.CompanyManager, Permission.WorkplaceManager],
        profiles: [Profile.Admin, Profile.CompanyManager, Profile.WorkplaceManager],
        show: true
      }
      ,
      {
        label: 'Informes',
        icon: 'pi pi-fw pi-book',
        routerLink: ['/reports'],
        roles: [Permission.Admin, Permission.CompanyManager, Permission.WorkplaceManager],
        profiles: [Profile.Admin, Profile.CompanyManager, Profile.WorkplaceManager],
        show: true
      }
      , {
        label: 'Logs',
        iconFamily: 'fontawesome',
        icon: ['far', 'rectangle-list'],
        routerLink: ['/logs'],
        roles: [Permission.Admin],
        profiles: [Profile.Admin, Profile.CompanyManager],
        show: true,
      }
      , {
        roles: [Permission.Admin, Permission.CompanyManager],
        profiles: [Profile.Admin, Profile.CompanyManager],
        separator: true,
      }
      ,
      {
        label: 'Calendarios',
        icon: 'pi pi-fw pi-calendar',
        routerLink: ['/calendars'],
        roles: [Permission.Admin, Permission.CompanyManager],
        profiles: [Profile.Admin, Profile.CompanyManager],
        show: true
      },
      {
        label: 'Jornadas',
        iconFamily: 'fontawesome',
        icon: ['fas', 'business-time'],
        routerLink: ['/work-schedules'],
        roles: [Permission.Admin, Permission.CompanyManager],
        profiles: [Profile.Admin, Profile.CompanyManager],
        show: true
      },
      {
        label: 'Ausencias',
        icon: 'pi pi-fw pi-calendar-times',
        routerLink: ['/days-off'],
        roles: [Permission.Admin, Permission.CompanyManager],
        profiles: [Profile.Admin, Profile.CompanyManager],
        show: true
      }

      , {
        roles: [Permission.Admin],
        profiles: [Profile.Admin],
        separator: true,
      }
      , {
        label: 'Tenants',
        iconFamily: 'fontawesome',
        icon: ['fas', 'building-flag'],
        routerLink: ['/tenants'],
        roles: [Permission.Admin],
        profiles: [Profile.Admin],
        show: true,
      }
      , {
        label: 'Versiones',
        iconFamily: 'fontawesome',
        icon: ['fas', 'timeline'],
        routerLink: ['/versions'],
        roles: [Permission.Admin],
        profiles: [Profile.Admin],
        show: true
      }

    ];
  }

  checkItemMenuProfile(profile: Profile, url: string) {
    let searchUrl = url.split("/")[1];
    let item = this.model.find(p => p.profiles && p.routerLink && p.routerLink.includes("/" + searchUrl));
    if (!item || !item.profiles.includes(profile)) {
      this.router.navigate(['/dashboard']);
    }
  }
  checkProfile(itemProfiles) {
    let profileOk = itemProfiles ? itemProfiles.includes(this.profile) : true;
    if (profileOk && this.profile == Profile.Admin && itemProfiles.length > 1)
      profileOk = getCompanyStorage() != null

    return profileOk;
  }

  showDivider(index: number) {
    return index == 0 || this.model[index - 1].section != this.model[index].section;
  }
}
