import { Component, ViewChild } from '@angular/core';
import { LatestVersionsComponent } from 'src/app/components/latest-versions/latest-versions.component';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    styleUrls: ['./app.footer.component.scss']
})
export class AppFooterComponent {

    currentApplicationVersion = environment.appVersion;
    @ViewChild('versionsComponent') versionsComponent: LatestVersionsComponent;

    constructor(public app: AppComponent) { }

    openVersions() {
        this.versionsComponent.showDialog();
    }
}
