import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getUserDefaultProfile, getUserEmployeeId, getUserRoles } from 'src/app/core/functions';
import { AuthService } from 'src/app/auth/auth.service';
import { Permission } from 'src/app/core/enums/permissions';
import { SharedSytleService } from 'src/app/shared/shared-style.service';
import { Profile } from 'src/app/core/enums/profile';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopbarComponent {

    employeeId: string;
    loading: boolean = true;
    isAdmin: boolean;
    logo: string;
    profile: Profile;
    subsAuth: Subscription = null;
    constructor(public app: AppComponent,
        public appMain: AppMainComponent,
        public afAuth: AngularFireAuth,
        public authService: AuthService,
        public sharedSytleService: SharedSytleService) {
    }

    ngOnDestroy(): void {
        this.subsAuth?.unsubscribe();
    }

    ngOnInit(): void {
        this.logo = this.sharedSytleService.logo;
        this.subsAuth = this.afAuth.idTokenResult.subscribe((user) => {
            if (user) {
                this.employeeId = getUserEmployeeId(user.claims);
                this.profile = getUserDefaultProfile(user.claims);
                this.isAdmin = getUserRoles(user.claims).includes(Permission.Admin);
                this.loading = false;

            }
        });
    }

    logout() {
        this.authService.SignOut();
    }
}
