import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { LatestVersionsComponent } from "./latest-versions.component";
import { DialogModule } from "primeng/dialog";
import { DataViewModule } from "primeng/dataview";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { VersionsService } from "src/app/pages/versions/versions.service";

@NgModule({
    declarations: [
        LatestVersionsComponent

    ],
    exports: [LatestVersionsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        DialogModule,
        DataViewModule
    ],
    providers: [
        VersionsService
    ]
})

export class LatestVersionsModule { }