<p-dialog [(visible)]="welcomeDialog" styleClass=" p-fluid sm:w-10 lg:w-3 md:w-4 h-14rem" [modal]="true"
    (onHide)="cancel()">
    <ng-template pTemplate="content">
        <div class="progress-spinner" *ngIf="loadingSpinner">
            <p-progressSpinner class="spinner"></p-progressSpinner>
        </div>
        <app-status [welcomeMessage]="true" (statusChange)="statusChange($event)"
            (loadedStatus)="loadedStatus($event)"></app-status>
    </ng-template>
    <ng-template pTemplate="footer">
    </ng-template>
</p-dialog>