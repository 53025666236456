import { Injectable } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Injectable()


export class SharedSytleService {
    public logo: string = 'assets/layout/images/yobing-';
    public title: string = "Yobing - Panel";

    constructor(private titleService: Title) {

    }

    setLogo(logo: string) {
        this.logo = logo;
    }

    setTitle(setTitle: string) {
        this.title = setTitle;
        this.titleService.setTitle(this.title);
    }
    setDefaultValues() {
        this.titleService.setTitle(this.title);
    }

}