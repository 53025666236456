import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { environment } from "src/environments/environment"
import { ConfigurationModel } from "./configuration/configuration.model"
import { EmployeeModel } from "../employees/detail/employee.model"

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient) { }

  private buildUrl(id?: string): string {
    return `${environment.api}/account/configuration`
  }

  public getConfiguration(): Observable<ConfigurationModel> {
    return this.http.get<ConfigurationModel>(this.buildUrl());
  }
  public updateConfiguration(configuration: ConfigurationModel): Observable<any> {
    return this.http.put<any>(this.buildUrl(), configuration);
  }

  public getProfile(): Observable<any> {
    return this.http.get<any>(`${environment.api}/account/profile`);
  }

}
