<div [ngClass]="{'status':(!isMobile() || isOverlay() || isHorizontal())
    &&!welcomeMessage, 'status-mobile':(isMobile() || isOverlay() || isHorizontal())&&!welcomeMessage}">
  <div *ngIf="!loading && !isAdmin" [class]="welcomeMessage?'no-card':'card'">

    <div class="w-full mb-3 text-center" *ngIf="welcomeMessage">
      Bienvenido {{userName}}
    </div>
    <div [class]="'w-full flex-wrap flex flex-row' + (welcomeMessage?' justify-content-center':'')">
      <div class="flex justify-content-start mb-2" *ngIf="changeProfile &&!welcomeMessage">
        <p-selectButton *ngIf="changeProfile" (onChange)="onChangeProfile($event)" class="mr-1" [options]="profiles"
          [(ngModel)]="profile" optionLabel="label" optionValue="value"></p-selectButton>
      </div>

      <div [class]="welcomeMessage?'flex mb-2':'flex flex-grow-1 justify-content-end mb-2'">
        <app-avatar [employeeId]="employeeId" [profile]="profile" *ngIf="!welcomeMessage && employeeId"
          class="hidden md:flex"></app-avatar>
        <ng-container *ngFor="let e of eventTypes;let i= index;">
          <p-button *ngIf="disableButton(e) && e.actionType!=jobAction" (click)="sendEvent(e,false)"
            [class]="(changeProfile? 'mr-1 md:mr-3':'mr-3')" [icon]="getIconEvent(e)" [label]="e.name"></p-button>
          <p-splitButton *ngIf="disableButton(e) && e.actionType==jobAction" [label]="labelJobAction"
            [icon]="iconJobAction" (onClick)="startWork(isRemoteWork)" [model]="items"
            [class]="(changeProfile? 'mr-2 md:mr-3':'mr-3')"></p-splitButton>
        </ng-container>
        <div class="mt-2">
          <app-status-timer [initialValue]="initDate" [start]="isWorking(status)"></app-status-timer>
        </div>
      </div>
    </div>
  </div>
</div>