import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EmployeeStatusModel } from "./employee-status.model";
import { DeviceType } from "src/app/core/enums/device-type-type";

@Injectable()
export class EventService {
  constructor(private http: HttpClient) { }

  private buildUrl(id?: string): string {
    return `${environment.api}/events?status=true`
  }

  public create(evenTypeId: string, remoteWork: boolean): Observable<EmployeeStatusModel> {
    let event = {
      eventTypeId: evenTypeId,
      deviceType: DeviceType.Backoffice,
      remoteWork: remoteWork
    };
    return this.http.post<EmployeeStatusModel>(this.buildUrl(), event);
  }
}
