import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, tap, Subscription } from 'rxjs';
import { EmployeeStatusModel } from '../pages/status/employee-status.model';
import { FirebaseService } from '../core/services/firebase.service';
import { setWelcomeStorage } from '../core/functions-localStorage';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class SharedUserDataService {
    private subjUpdateStatus = new Subject<boolean>();
    private subjStatus = new Subject<EmployeeStatusModel>();
    private subjAvatar = new Subject<AvatarModel>();
    private subjWelcomeMessage = new Subject<boolean>();
    private avatar: AvatarModel;
    private showWelcomeMessage: boolean;

    private cachedNotifications$: BehaviorSubject<any> = new BehaviorSubject(null);
    private employeeId: string = null;
    private subsNotifications: Subscription;


    constructor(private firebaseService: FirebaseService) { }

    public getCurrentStatus(): Observable<EmployeeStatusModel> {
        return this.subjStatus.asObservable();
    }

    public updateCurrentStatus(status: EmployeeStatusModel): void {
        this.subjStatus.next(status);
    }

    public updateBDCurrentStatus(update: boolean): void {
        this.subjUpdateStatus.next(update);
    }
    public getUpdateBDCurrentStatus(): Observable<boolean> {
        return this.subjUpdateStatus.asObservable();
    }


    public getAvatar(): Observable<AvatarModel> {
        return this.subjAvatar.asObservable();
    }

    public updateAvatar(avatar: AvatarModel): void {
        this.avatar = avatar;
        this.subjAvatar.next(avatar);
    }

    public getAvatarValue(): AvatarModel {
        return this.avatar;
    }


    public getWelcomeMessage() {
        return this.subjWelcomeMessage.asObservable();
    }
    public getWelcomeMessageValue() {
        return this.showWelcomeMessage;
    }

    public setWelcomeMessage(show: boolean) {
        this.showWelcomeMessage = show;
        setWelcomeStorage(show);
        this.subjWelcomeMessage.next(show);
    }

    public getNotifications(employeeId: string): Observable<any> {
        // Intentamos obtener el documento en caché
        const cachedNotifications = this.cachedNotifications$.value;

        if (cachedNotifications && this.employeeId == employeeId) {
            // Si el documento ya está en caché, lo devolvemos como un observable
            return this.cachedNotifications$.asObservable();
        } else {
            this.employeeId = employeeId;
            // Si no está en caché, realizamos la consulta a Firestore y nos suscribimos a los cambios
            let obsNotifications = this.firebaseService.getNotifications(employeeId);
            this.subsNotifications?.unsubscribe;
            this.subsNotifications = obsNotifications.subscribe(
                {
                    next: d => {
                        this.cachedNotifications$.next(d);
                    },
                    error: (e) => {
                        if (!environment.production)
                            console.log('obsNotifications', e);
                    }
                });
            return obsNotifications.pipe(
                tap(document => {
                    this.cachedNotifications$.next(document);
                })
            );
        }
    }
    public clearNotifications() {
        this.employeeId = null;
        this.subsNotifications?.unsubscribe;
        this.cachedNotifications$.next(null); // Borramos la caché si es necesario
    }


}


export interface AvatarModel {
    url: string;
    initials: string;
    fullName: string;
}


