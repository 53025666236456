<div class="layout-menu-wrapper" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}"
  (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.onSidebarMouseOver($event)"
  (mouseleave)="appMain.onSidebarMouseLeave($event)">
  <div class="menu-logo" *ngIf="!appMain.isHorizontal() || appMain.isMobile()">
    <a href="/" class="app-name" *ngIf="!appMain.isSlim() || appMain.isMobile()">
      <img *ngIf="app.colorScheme" alt="Yobing" [src]="logo+ app.colorScheme + '.png'" />
    </a>
    <a href="/" class="menu-pin" (click)="appMain.onToggleMenu($event)">
      <span *ngIf="appMain.isOverlay()" class="pi pi-times"></span>
      <span *ngIf="appMain.isSidebar() && !appMain.sidebarStatic && appMain.pinActive" class="pi pi-unlock"></span>
      <span *ngIf="appMain.isSidebar() && appMain.sidebarStatic && appMain.pinActive" class="pi pi-lock"></span>
    </a>
  </div>
  <div class="layout-menu-container">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of model; let i = index;">
        <ng-container *ngxPermissionsOnly="item.roles">
          <ng-container *ngIf="checkProfile(item.profiles)">
            <li app-menuitem *ngIf="!item.separator && item.show" [item]="item" [index]="i" [root]="true"></li>
            <li *ngIf="item.separator" class="menu-separator"></li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </div>
  <app-inlinemenu></app-inlinemenu>
</div>