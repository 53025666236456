import { NgModule } from "@angular/core";
import { StatusComponent } from "./status.component";
import { EmployeeStatusService } from "./employee-status.service";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { StatusTimerComponent } from "./status-timer.component";
import { EventTypesService } from "./event-type.service";
import { EventService } from "./event.service";
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from "primeng/tooltip";
import { CustomAvatarModule } from "src/app/components/avatar/avatar.module";
@NgModule({
  declarations: [
    StatusComponent,
    StatusTimerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    ReactiveFormsModule,
    ButtonModule,
    SelectButtonModule,
    SplitButtonModule,
    CustomAvatarModule

  ],
  providers: [
    EmployeeStatusService,
    EventTypesService,
    EventService,
  ],
  exports: [
    StatusComponent
  ]
})

export class StatusModule { }
