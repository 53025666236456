import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ActionType } from "src/app/core/enums/action-type-type";

@Injectable()
export class EventTypesService {
  constructor(private http: HttpClient) { }

  private buildUrl(id?: string): string {
    return `${environment.api}/event-types`
  }

  public getAll(): Observable<EventTypeModel[]> {
    return this.http.get<EventTypeModel[]>(this.buildUrl());
  }
}

export interface EventTypeModel {
  id: string;
  name: string;
  actionType: ActionType;
}
