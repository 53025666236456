<div class="layout-breadcrumb-container flex align-items-center justify-content-between">
    <div class="layout-breadcrumb-left-items flex align-items-center">
        <a href="#" class="menu-button" (click)="appMain.onMenuButtonClick($event)"
            *ngIf="appMain.isStatic() && !appMain.isMobile()">
            <i class="pi pi-bars"></i>
        </a>

        <p-breadcrumb [model]="items" styleClass="layout-breadcrumb p-py-2"></p-breadcrumb>
    </div>
    <div class="layout-breadcrumb-right-items flex">
        <span *ngIf="company">
            {{company.businessName}}</span>
    </div>
</div>