import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CompanyModel } from './company.model';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient) { }

  getCompany(): Observable<CompanyModel> {
    return this.http.get<CompanyModel>(environment.api + '/company');
  }

  updateCompany(company: CompanyModel): Observable<any> {
    return this.http.put<any>(environment.api + "/company/", company);
  }

  removeLogo(): Observable<any> {
    return this.http.delete<CompanyModel>(environment.api + '/company/logo');
  }
}
