import { Component, Input, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FirebaseService, NotificationFB } from "src/app/core/services/firebase.service";
import { SharedUserDataService } from "src/app/shared/shared-user-data.service";
import { Subscription } from "rxjs";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import { Profile } from "src/app/core/enums/profile";
import { DayOffStatus, DayOffStatusType } from "src/app/core/enums/day-off-status-type";
import { DatePipe } from "@angular/common";
import { DayOffTypeType } from "src/app/core/enums/day-off-type-type";
import { NotificationType } from "src/app/core/enums/notification-type-type";

@Component({
  templateUrl: './avatar.component.html',
  selector: 'app-avatar',
  styleUrls: ['./avatar.component.scss']
})

export class AvatarComponent implements OnInit, OnDestroy {
  @Input() employeeId: string;
  @Input() profile: Profile;
  public userName: string;
  public initials: string;
  public avatar: string;
  public notifications: any[] = [];
  public allNotifications: any[] = [];
  public itemsNotifications: MenuItem[] = []
  private subsAvatar: Subscription;
  private subsNotifications: Subscription;

  constructor(
    public app: AppComponent,
    public afAuth: AngularFireAuth,
    public firebaseServie: FirebaseService,
    public sharedUserDataService: SharedUserDataService,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    this.intiAvatar();
  }


  ngOnDestroy(): void {
    this.subsAvatar?.unsubscribe();
    this.subsNotifications?.unsubscribe();
  }
  ngOnInit(): void {
    this.subsAvatar?.unsubscribe();
    this.subsAvatar = this.sharedUserDataService.getAvatar()
      .subscribe(avatar => {
        this.avatar = avatar.url;
        this.initials = avatar.initials;
        this.userName = avatar.fullName;
      });

    if (this.employeeId) {
      this.subsNotifications?.unsubscribe();
      this.subsNotifications = this.sharedUserDataService.getNotifications(this.employeeId)
        .subscribe(notifications => {
          this.allNotifications = notifications;
          this.setItemNotifications(this.allNotifications);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.allNotifications && this.employeeId
      && (changes.profile && changes.profile.currentValue != changes.profile.previousValue)) {
      this.setItemNotifications(this.allNotifications);
    }
  }

  private setItemNotifications(notifications: NotificationFB[]) {
    this.itemsNotifications = [
      {
        label: 'Solicitudes',
        items: this.getRequestsNotificacions(notifications),
      }];
  }
  private getRequestsNotificacions(notifications: NotificationFB[]): MenuItem[] {
    const statusList = this.getAllowedStatusRequest();
    let requests = [];
    this.notifications = notifications?.filter(n => n.request
      && statusList.includes(n.request.status));
    this.notifications?.forEach(n => {
      const request: MenuItem = {
        label: this.getMenuRequestLabel(n),
        styleClass: "request",
        escape: false,
        icon: DayOffStatusType.mapNotificationIcon(n.request.status),
        iconClass: DayOffStatusType.mapStatusSeverity(n.request.status),
        command: () => {
          this.update(n.type, statusList);
        },
      }
      requests.push(request);
    })

    return requests;

  }
  private getAllowedStatusRequest(): DayOffStatus[] {
    const statusList = (this.profile == Profile.CompanyManager
      || this.profile == Profile.WorkplaceManager)
      ? [DayOffStatus.Pending, DayOffStatus.Renounced]
      : [DayOffStatus.Approved, DayOffStatus.Rejected, DayOffStatus.Renounced];

    return statusList;
  }
  private getMenuRequestLabel(notification: NotificationFB): string {
    let label = `<ul><li>
    ${DayOffTypeType.mapType(notification.request.type) + " "
      + DayOffStatusType.mapNotifcationStatus(notification.request.status)
        .toLocaleLowerCase()
      }</li>`;

    if (notification.request.status == DayOffStatus.Pending
      || (notification.request.status == DayOffStatus.Renounced)
      && (this.profile == Profile.CompanyManager
        || this.profile == Profile.WorkplaceManager))
      label += `<li class="text-xs request-range employee-name"> 
    ${notification.request.employeeName}</li>`;

    notification.request.days?.forEach(day => {
      label += `<li class="text-xs request-range">
      ${this.getMenuDate(day.start, day.end)}</li>`;
    });
    return label + "</ul>";
  }
  private getMenuDate(start: string, end: string): string {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return end == start
      ? `${startDate.getDate()} de ${this.datePipe.transform(startDate, 'MMMM')}`
      : `Del ${startDate.getDate()}
        ${startDate.getMonth() != endDate.getMonth() ? " de "
        + this.datePipe.transform(startDate, 'MMMM') : ""}
        al ${endDate.getDate()} de ${this.datePipe.transform(endDate, 'MMMM')}`;
  }
  private update(type: NotificationType, statusList?: DayOffStatus[]) {
    this.firebaseServie.markAsReadAllNotification(this.employeeId, type, statusList);
    if (type == NotificationType.Request) {
      if (this.profile == Profile.CompanyManager || this.profile == Profile.WorkplaceManager)
        this.router.navigate(['/requests']);
      if (this.profile == Profile.Employee)
        this.router.navigate(['/my-requests']);
    }

  }

  onHideMenu(event) {
  }
  intiAvatar() {
    const avatar = this.sharedUserDataService.getAvatarValue();
    if (avatar) {
      this.avatar = avatar.url;
      this.initials = avatar.initials;
      this.userName = avatar.fullName;
    }
  }

  isOverlay() {
    return this.app.menuMode === 'overlay';
  }

  isHorizontal() {
    return this.app.menuMode === 'horizontal';
  }
  isMobile() {
    return window.innerWidth <= 991;
  }



}
