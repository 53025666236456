import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { getCompanyStorage } from '../core/functions-localStorage';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AngularFireAuth) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.idToken.pipe(
      mergeMap((token: any) => {
        if (token) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
            params: this.getParams(request)
          });
        }
        return next.handle(request);
      }));
  }

  getParams(request: HttpRequest<any>): HttpParams {
    let company = getCompanyStorage()
    if (company != null)
      return (request.params ? request.params : new HttpParams())
        .set('company', company.id)
    return request.params
  }
}
