import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { EmployeeStatus } from "../enums/employee-status-type";
import { Observable, take } from "rxjs";
import { environment } from "src/environments/environment";
import { DateToyyyyMMddTHHmmssZ, getFullName } from "../functions";
import { DayOffType } from "../enums/day-off-type-type";
import { DayOffStatus } from "../enums/day-off-status-type";
import * as moment from "moment";
import { EmployeeListModel } from "../../pages/employees/employee-list.model";
import { RequestDaysOffModel } from "../../pages/employee-requests/employee-request.model";
import { CompanyRequestDaysOffModel } from "../../pages/employees-requests/employees-request.model";
import { NotificationType } from "../enums/notification-type-type";
import { CompanyListModel } from "../../pages/admin-companies/company-list.model";

@Injectable()
export class FirebaseService {
  constructor(private afs: AngularFirestore
  ) { }

  /*****Company******/
  public getCompanyStatus(companyId: string): Observable<CompanyEmployeesStatusFB> {
    return this.afs.collection<CompanyEmployeesStatusFB>(environment.firebaseCollections.companies).doc(companyId).valueChanges();
  }

  public fixCompnayStatus(company: CompanyListModel, employees: EmployeeListModel[], requests: CompanyRequestDaysOffModel[]) {
    this.afs.collection<CompanyEmployeesStatusFB>(environment.firebaseCollections.companies)
      .doc(company.id).valueChanges().pipe(take(1)).subscribe(companyFB => {
        const yesterday = moment().subtract(1, 'days');
        let employeesFB: EmployeeStatusFB[] = []
        employees.forEach(employee => {
          let employeeFB: EmployeeStatusFB = getEmployeeFB(employee);
          let requestsEmp = requests.filter(r => r.employeeId == employee.id);
          let daysOff: DayOffFB[] = [];
          requestsEmp.forEach(req => {
            let approvedDays = req.days.filter(d => d.status == DayOffStatus.Approved);
            let reqDates = RequestDaysOffModel.getDates(approvedDays);
            daysOff = daysOff.concat(reqDates.filter(d => moment(d).isAfter(yesterday)).map(d => ({ date: d, type: req.type })));
          })
          employeeFB.daysOff = daysOff;
          employeesFB.push(employeeFB);
        })

        if (!companyFB) companyFB = { id: company.id, employees: employeesFB, allowEmployeesSeeCompanyStatus: company.allowEmployeesSeeCompanyStatus };
        else {
          companyFB.allowEmployeesSeeCompanyStatus = company.allowEmployeesSeeCompanyStatus;
          employeesFB.forEach(emp => {
            let employeeIndex = companyFB.employees.findIndex(e => e.id == emp.id);
            if (employeeIndex >= 0) {
              companyFB.employees[employeeIndex].workplaceId = emp.workplaceId;
              companyFB.employees[employeeIndex].daysOff = emp.daysOff;
              companyFB.employees[employeeIndex].hireDate = emp.hireDate;
              companyFB.employees[employeeIndex].contractEndDate = emp.contractEndDate;
            }
            else { companyFB.employees.push(emp); }
          })
        }
        this.afs.collection<CompanyEmployeesStatusFB>(environment.firebaseCollections.companies).doc(company.id).set(companyFB)
          .catch((e) => {
            if (!environment.production)
              console.log('fixCompnayStatus set', e);
          });
      });
  }

  /*****Employee******/


  /*****Notifications******/
  public getNotifications(employeeId: string): Observable<NotificationFB[]> {
    return this.afs.collection<NotificationFB>
      (environment.firebaseCollections.notifications, ref => ref.where('employeeId', '==', employeeId)
        .where('read', '==', false).orderBy('createdOn', 'desc')).valueChanges();
  }
  public markAsReadAllNotification(employeeId: string, type: NotificationType, reqStatus?: DayOffStatus[]) {
    const updateOn = DateToyyyyMMddTHHmmssZ(new Date());
    const queryFB = (ref) => ref.where('employeeId', '==', employeeId)
      .where('read', '==', false)
      .where('type', '==', type);
    const collectionRef = this.afs.collection<NotificationFB>
      (environment.firebaseCollections.notifications, queryFB);

    collectionRef.valueChanges().pipe(take(1)).subscribe(
      {
        next: (notifications) => {
          notifications.forEach((notification) => {
            if (!reqStatus ||
              (notification.request && reqStatus.includes(notification.request.status))) {
              const documentoRef = collectionRef.doc(notification.id);
              documentoRef.update({ read: true, updateOn: updateOn })
                .catch((e) => {
                  if (!environment.production)
                    console.log('markAsReadAllNotification update', e);
                });
            }
          });
        },
        error: (e) => {
          if (!environment.production)
            console.log('markAsReadAllNotification', e);
        }
      });
  }
  public updateNotification(notificationId: string, employeeId: string) {
    this.afs.collection<NotificationFB>(environment.firebaseCollections.notifications)
      .doc(notificationId).valueChanges().pipe(take(1)).subscribe({
        next: (notification) => {
          if (notification && notification.employeeId == employeeId) {
            notification.read = true;
            notification.updateOn = DateToyyyyMMddTHHmmssZ(new Date());
            this.afs.collection<NotificationFB>(environment.firebaseCollections.notifications).doc(notificationId).set(notification)
              .catch((e) => {
                if (!environment.production)
                  console.log('updateNotification set', e);
              });
          }
        },
        error: (e) => {
          if (!environment.production)
            console.log('updateNotification', e);
        }

      });
  }

  /******Faqs******/
  public getFaqs(id: string): Observable<FaqFB> {
    return this.afs.collection<FaqFB>
      (environment.firebaseCollections.faqs).doc(id).valueChanges();
  }

}



//Clases
export interface CompanyEmployeesStatusFB {
  id: string;
  allowEmployeesSeeCompanyStatus?: boolean;
  employees: EmployeeStatusFB[];
}
export class EmployeeStatusFB {
  id: string;
  name: string;
  avatar?: string;
  status?: EmployeeStatus;
  registerExempt: boolean;
  remoteWork: boolean;
  workplaceId: string;
  daysOff?: DayOffFB[];
  hireDate :string;
  contractEndDate  :string;
}
export interface DayOffFB {
  date: string;
  type: DayOffType;
}

export interface NotificationFB {
  id: string;
  employeeId: string;
  compnayId: string;
  workplaceId: string;
  request: NotificationRequestFB;
  type: NotificationType;
  read: boolean;
  updateOn: string;
  createdOn: string;
}
export interface NotificationRequestFB {
  employeeId: string;
  employeeName: string;
  type: DayOffType;
  status: DayOffStatus;
  days: NotificationRequestDaysFB[];
}
export interface NotificationRequestDaysFB {
  start: string;
  end: string;
  totalDays: number
}

export interface FaqFB {
  id: string;
  info: string;
  items: FaqItemFB[];
}

export interface FaqItemFB {
  label: string;
  icon: string;
  questions: FaqQuestionFB[];
}

export interface FaqQuestionFB {
  question: string;
  answer: string;
}


function getEmployeeFB(employee: EmployeeListModel): EmployeeStatusFB {
  let employeeFB: EmployeeStatusFB = {
    id: employee.id, workplaceId: employee.workplaceId,
    name: getFullName(employee.name, employee.lastName),
    status: employee.status ? employee.status : EmployeeStatus.Offline,
    avatar: employee.avatar, registerExempt: employee.registerExempt,
    remoteWork: employee.status == EmployeeStatus.Remote,
    hireDate: employee.hireDate ? moment(employee.hireDate).format('YYYY-MM-DD'):'',
    contractEndDate:employee.contractEndDate? moment(employee.contractEndDate).format('YYYY-MM-DD'):''
  };
  return employeeFB;
}

