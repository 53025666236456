import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { EmployeeStatusService } from "./employee-status.service";
import { EmployeeStatusModel } from "./employee-status.model";
import { ToastNotifierService } from "src/app/shared/toast-notifier.service";
import { EmployeeStatus, EmployeeStatusType } from "src/app/core/enums/employee-status-type";
import { EventTypeModel, EventTypesService } from "./event-type.service";
import { EventService } from "./event.service";
import { ActionType } from "src/app/core/enums/action-type-type";
import { AppComponent } from "src/app/app.component";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { formatErrorMessage, getUserDefaultProfile, getUserCompanyId, getUserEmployeeId, getUserRoles, getUserWorkplace, getUserName } from "src/app/core/functions";
import { Permission } from "src/app/core/enums/permissions";
import { ProfileType } from "src/app/core/enums/profile";
import { ProfileService } from "src/app/shared/profile.service";
import { SharedUserDataService } from "src/app/shared/shared-user-data.service";
import { Subscription, take } from "rxjs";
import { MenuItem } from "primeng/api";
import { getRemoteWorkStorage, setRemoteWorkSotrage } from "src/app/core/functions-localStorage";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: './status.component.html',
  selector: 'app-status',
  styleUrls: ['./status.component.scss']
})

export class StatusComponent implements OnInit, OnDestroy {


  @Input() welcomeMessage: boolean;
  @Output() statusChange = new EventEmitter<boolean>();
  @Output() loadedStatus = new EventEmitter<boolean>();

  loading: boolean = true;
  defaultTime: string = "00:00:00";
  isAdmin: boolean;
  changeProfile: boolean;
  profiles: ProfileType[] = [];
  profile;


  public initDate: Date;
  public status: EmployeeStatusModel;
  public startTimer: boolean;
  public eventTypes: EventTypeModel[] = [];
  public companyId: string;
  public workplaceId: string;
  public employeeId: string;
  private subsAfAuth: Subscription;
  private subsCurrentStatus: Subscription;
  private subsGetStatus: Subscription;
  private subsGetBDCurrentStatus: Subscription;
  private visibilityChangeListener: () => void;

  userName: string;
  iconJobAction: string;
  labelJobAction: string;
  isRemoteWork: boolean;
  jobAction = ActionType.Job;
  items: MenuItem[];
  iconWorking: string = 'pi pi-play';
  iconRemote: string = 'pi pi-desktop';
  showWelcomeMessage: boolean;

  constructor(
    private statusService: EmployeeStatusService,
    private eventTypesService: EventTypesService,
    private eventsService: EventService,
    private toastNotifier: ToastNotifierService,
    public app: AppComponent,
    public afAuth: AngularFireAuth,
    public profileService: ProfileService,
    public sharedUserDataService: SharedUserDataService
  ) {


    this.visibilityChangeListener = () => {
      if (!document.hidden) {
        this.getCurrentStatus();
      }
    };
  }


  ngOnDestroy(): void {
    this.subsAfAuth?.unsubscribe();
    this.subsGetStatus?.unsubscribe();
    this.subsCurrentStatus?.unsubscribe();
    this.subsGetBDCurrentStatus?.unsubscribe();
    document.removeEventListener('visibilitychange', this.visibilityChangeListener);
  }
  ngOnInit(): void {
    this.initJobButton();
    this.subsAfAuth?.unsubscribe();
    this.subsAfAuth = this.afAuth.idTokenResult.subscribe((user) => {
      if (user) {
        this.userName = getUserName(user.claims);
        let roles = getUserRoles(user.claims);
        this.isAdmin = roles.includes(Permission.Admin);
        if (!this.isAdmin) {
          this.intiProfileButton(user, roles);
          this.companyId = getUserCompanyId(user.claims);
          this.employeeId = getUserEmployeeId(user.claims);
          this.workplaceId = getUserWorkplace(user.claims);
          this.getCurrentStatus();
          this.refreshWindow();

          this.eventTypesService.getAll().pipe(take(1)).subscribe({
            next: eventTypes => {
              this.eventTypes = eventTypes;
            },
            error: error => {
              // this.toastNotifier.fail(formatErrorMessage(error, "Error al obtener los tipos de eventos"));
            }
          })
        }
      }
    });

  }

  /** Configuraciones **/
  intiProfileButton(user, roles) {
    this.profile = getUserDefaultProfile(user.claims);
    this.profiles = ProfileType.getFilterTypes(roles);
    this.changeProfile = roles.includes(Permission.CompanyManager) || roles.includes(Permission.WorkplaceManager);
  }

  initJobButton() {
    const isRemoteWork = getRemoteWorkStorage();
    this.configJobButton(isRemoteWork);
  }

  configJobButton(isRemoteWork: boolean) {
    this.isRemoteWork = isRemoteWork;
    this.labelJobAction = isRemoteWork ? 'Teletrabajar' : "Entrar";
    this.iconJobAction = isRemoteWork ? this.iconRemote : this.iconWorking;
    this.items = [
      {
        label: isRemoteWork ? 'Entrar' : "Teletrabajar",
        icon: isRemoteWork ? this.iconWorking : this.iconRemote,
        command: () => {
          this.startWork(!isRemoteWork);
        }
      }
    ];
  }

  /** Estado **/
  getCurrentStatus() {
    this.subsGetStatus?.unsubscribe();
    this.subsGetStatus = this.statusService.getStatus().pipe(take(1)).subscribe({
      next: status => {
        if (!environment.production) console.log("status", status);
        if (this.status?.status && status?.status &&
          status?.status != this.status?.status) {
          this.sharedUserDataService.setWelcomeMessage(true);
        }
        this.status = status;
        this.sharedUserDataService.updateCurrentStatus(this.status);
        let timeString = status.timeSummary?.workingTime ?? this.defaultTime;
        this.initDate = new Date(`1970-01-01T${timeString}`);
        this.loading = false;
        this.getSharedCurrentStatus();
        this.loadedStatus.emit(true);
      },
      error: error => {
        this.toastNotifier.fail(formatErrorMessage(error, "No se ha podido obtener el estado."));
      }
    })
  }

  getSharedCurrentStatus() {
    this.subsCurrentStatus?.unsubscribe();
    if (this.sharedUserDataService.getWelcomeMessageValue()) {
      this.subsCurrentStatus = this.sharedUserDataService.getCurrentStatus()
        .subscribe(status => {
          this.status = status;
          let timeString = status.timeSummary?.workingTime ?? this.defaultTime;
          this.initDate = new Date(`1970-01-01T${timeString}`);
          if (!this.sharedUserDataService.getWelcomeMessageValue())
            this.subsCurrentStatus?.unsubscribe();
        });
    }
    this.subsGetBDCurrentStatus?.unsubscribe();
    this.sharedUserDataService.getUpdateBDCurrentStatus()
      .subscribe(x => {
        this.getCurrentStatus();
      });

  }



  refreshWindow() {
    document.addEventListener('visibilitychange', this.visibilityChangeListener);
  }


  startWork(remoteWork: boolean) {
    const eventType = this.eventTypes.find(e => e.actionType == ActionType.Job);
    setRemoteWorkSotrage(remoteWork);
    this.configJobButton(remoteWork);
    this.sendEvent(eventType, remoteWork);
  }
  sendEvent(eventType: EventTypeModel, remoteWork: boolean) {
    this.eventsService.create(eventType.id, remoteWork).pipe(take(1)).subscribe({
      next: status => {
        this.status = status
        let timeString = status.timeSummary?.workingTime ?? this.defaultTime;
        this.initDate = new Date(`1970-01-01T${timeString}`);
        this.loading = false;
        this.sharedUserDataService.updateCurrentStatus(this.status);
        this.statusChange.emit(true);
      },
      error: error => {
        this.toastNotifier.fail(formatErrorMessage(error, "No se ha podido guardar el fichaje"));
      }
    })
  }

  getIconEvent(eventType: EventTypeModel): string {
    if (eventType.actionType == ActionType.End) {
      return "pi pi-stop";
    } else if (eventType.actionType == ActionType.Rest) {
      return "pi pi-pause";
    }
    else if (eventType.actionType == ActionType.Job) {
      return "pi pi-play";
    }
  }

  public isWorking(employeeStatus: EmployeeStatusModel): boolean {
    const startTypes: EmployeeStatus[] = [
      EmployeeStatus.Working,
      EmployeeStatus.Remote
    ]
    return startTypes.includes(employeeStatus?.status)
  }
  public disableButton(event: EventTypeModel): boolean {
    const job: EmployeeStatus[] = [
      EmployeeStatus.Offline,
      EmployeeStatus.Resting
    ]
    const rest: EmployeeStatus[] = [
      EmployeeStatus.Working,
      EmployeeStatus.Remote
    ]
    const end: EmployeeStatus[] = [
      EmployeeStatus.Working,
    ]

    switch (event.actionType) {
      case ActionType.Job:
        return job.includes(this.status.status);
      case ActionType.Rest:
        return rest.includes(this.status.status);
      case ActionType.End:
        return end.includes(this.status.status);
    }
  }



  getStatus(status: EmployeeStatus) {
    return EmployeeStatusType.mapStatus(status);
  }

  /*** Perfil****/
  onChangeProfile(event) {
    this.profileService.updateProfile(this.profile);
  }

  /*****/
  isOverlay() {
    return this.app.menuMode === 'overlay';
  }

  isHorizontal() {
    return this.app.menuMode === 'horizontal';
  }
  isMobile() {
    return window.innerWidth <= 991;
  }
}
