<div class="exception-body accessdenied">
    <div class="exception-panel">
        <h1>ACCESO</h1>
        <h3>denegado</h3>
        <p>No tienes permisos para acceder a esta página.</p>
        <button type="button" pButton label="Volver al inicio" (click)="goToHome()"></button>
    </div>
    <div class="exception-footer">
        <img [src]="'assets/layout/images/iformacion-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
            class="exception-appname" />
    </div>
</div>
