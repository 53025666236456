import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Version } from "./version";
import { take } from "rxjs";
import { formatErrorMessage } from "src/app/core/functions";
import { ToastNotifierService } from "src/app/shared/toast-notifier.service";
import { VersionsService } from "src/app/pages/versions/versions.service";
import { ChangeType, ChangeTypeType } from "src/app/core/enums/change-type-type";
import { VersionListModel } from "src/app/pages/versions/version.model";

@Component({
    selector: 'app-latest-versions',
    templateUrl: './latest-versions.component.html',
})
export class LatestVersionsComponent implements OnInit {

    @Output() getVersions = new EventEmitter<any>();
    loading: boolean = true;
    display: boolean;
    saving: boolean;
    empty: boolean = false;
    versions: VersionListModel[];

    constructor(private versionsService: VersionsService,
        private toastNotifier: ToastNotifierService) {
    }


    ngOnInit() {
    }

    showDialog() {
        this.display = true;
        this.loading = true;
        this.empty = false;
        this.versionsService.getLastVersions().pipe(take(1)).subscribe({
            next: result => {
                this.versions = result.items;
                this.loading = false;
                if (this.versions && this.versions.length == 0) {
                    this.empty = true;
                }
            },
            error: error => {
                this.loading = false;
                this.toastNotifier.fail(formatErrorMessage(error, "No se ha podido cargar los datos de las versiones"));
            }
        })

    }

    getType(type: ChangeType): string {
        return ChangeTypeType.mapType(type);
    }
    hideDialog() {
        this.display = false;
    }

}