import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppBreadcrumbService } from './app.breadcrumb.service';
import { Subscription, take } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AppMainComponent } from 'src/app/app.main.component';
import { getCompanyStorage } from 'src/app/core/functions-localStorage';
import { CompanyService } from 'src/app/pages/company/company.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getUserRoles } from 'src/app/core/functions';
import { Permission } from 'src/app/core/enums/permissions';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    items: MenuItem[];

    home: MenuItem;

    search: string;

    company: any;

    constructor(public breadcrumbService: AppBreadcrumbService,
        private companyService: CompanyService,
        public appMain: AppMainComponent,
        public afAuth: AngularFireAuth) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });

        this.home = { icon: 'pi pi-home', routerLink: '/' };
    }
    ngOnInit(): void {
        this.afAuth.idTokenResult.pipe(take(1)).subscribe((user) => {
            if (user) {
                const isAdmin = getUserRoles(user.claims).includes(Permission.Admin);
                if (isAdmin) {
                    this.companyService.getCompany().pipe(take(1)).subscribe({
                        next: company => {
                            this.company = company;
                        },
                        error: error => {

                        }
                    })
                }
            }
        });

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
