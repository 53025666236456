import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { Subscription, timer } from "rxjs";

@Component({
  template: '{{time}}',
  selector: 'app-status-timer',
})

export class StatusTimerComponent implements OnChanges, OnDestroy {
  @Input() initialValue: Date;
  @Input() start: boolean;
  public time: string;
  private subscription: Subscription;

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnChanges(): void {
    this.subscription?.unsubscribe();
    this.subscription = this.setClock(this.initialValue, this.start);
  }

  private setClock(initialValue: Date, start: boolean): Subscription {
    let hours = initialValue.getHours();
    let minutes = initialValue.getMinutes();
    let seconds = initialValue.getSeconds();

    if (start) {
      return timer(0, 1000)
        .subscribe({
          next: () => {
            seconds++;
            if (seconds === 60) {
              seconds = 0;
              minutes++;
            }
            if (minutes === 60) {
              minutes = 0;
              hours++;
            }
            this.setTimeString(hours, minutes, seconds);
          }
        })
    }
    else {
      this.ngOnDestroy();
      this.setTimeString(hours, minutes, seconds);
    }
  }

  private setTimeString(hours: number, minutes: number, seconds: number): void {
    this.time = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  }
}
