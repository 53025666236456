export enum ActionType {
  End = "end",
  Job = "job",
  Rest = "rest"
}
export class ActionTypeType {
  label: string;
  value: ActionType;
  static types: ActionTypeType[] = [
    { value: ActionType.End, label: 'Salida' },
    { value: ActionType.Job, label: 'Trabajo' },
    { value: ActionType.Rest, label: 'Descanso' }
  ]
  static mapType(type: ActionType): string {
    return this.types.filter(t => t.value == type).map<string>(f => f.label)[0];
  }
}
