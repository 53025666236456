import { Component } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './app.accessdenied.component.html',
})
export class AppAccessdeniedComponent {
  constructor(
    public router: Router,
    public app: AppComponent) {
  }
  goToHome(){
    this.router.navigate(['/dashboard'])
  }
}
