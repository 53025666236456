import { Injectable } from "@angular/core";
import { Theme } from "../core/enums/theme-type";
import { AccountService } from "../pages/account/account.service";
import { Observable, of, take } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppearanceService {
  public colorScheme: string;
  constructor(private accountService: AccountService) {
  }

  public loadConfiguration()
    : Observable<any> {
    return new Observable(
      observer => {
        this.accountService.getConfiguration()
          .pipe(take(1)).subscribe({
            next: config => {
              this.changeColorScheme(config.theme);
              this.colorScheme = config.theme;
              observer.next()
            },
            error: () => {
              this.changeColorScheme(Theme.Dark);
              this.colorScheme = Theme.Dark;
              observer.next();
            },
            complete: () => {
              observer.complete()
            }
          })
      })
  }

  public changeColorScheme(scheme: Theme) {
    this.changeStyleSheets('layout-css', 'layout-' + scheme + '.css', 1);
    this.changeStyleSheets('theme-css', 'theme-' + scheme + '.css', 1);
    this.colorScheme = scheme;
  }

  private changeStyleSheets(id: string, value: string, from: number) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');

    if (from === 1) {           // which function invoked this function - change scheme
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {       // which function invoked this function - change color
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  private isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  private replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }
}
