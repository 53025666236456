import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable()
export class ToastNotifierService {
  constructor(private messageService: MessageService) { }

  public fail(message: string) {
    this.messageService.add({ key: 'bc', severity: 'error', summary: 'Mensaje de error', detail: message });
  }

  public success(message: string) {
    this.messageService.add({ key: 'bc', severity: 'success', summary: 'Mensaje de éxito', detail: message });
  }
  public warn(message: string, summary?: string) {
    this.messageService.add({ key: 'bc', severity: 'warn', summary: summary, detail: message });
  }
}
