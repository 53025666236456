import { getUserDefaultProfile } from "./functions";

//welcome
export function setWelcomeStorage(welcome: boolean) {
    if (welcome)
        localStorage.setItem('welcome', JSON.stringify(welcome));
    else
        localStorage.removeItem('welcome');
}
//remoteWork
export function getRemoteWorkStorage(): boolean {
    const remoteWorkValue = localStorage.getItem('remoteWork');
    let isRemoteWork = remoteWorkValue != null ? JSON.parse(remoteWorkValue) : false;
    return isRemoteWork;
}
export function setRemoteWorkSotrage(remoteWork: boolean) {
    localStorage.setItem('remoteWork', JSON.stringify(remoteWork));
}
//profile
export function getProfileStorage(): string {
    let storegeProfile = localStorage.getItem("profile");
    return storegeProfile;
}
export function setProfileStorage(profile) {
    localStorage.setItem("profile", profile.toString());
}

export function setDefaultProfileStorage(claims) {
    const profile = getUserDefaultProfile(claims);
    setProfileStorage(profile)
}

//usage
export function getLogUsageStorage(): string {
    const usageSentValue = localStorage.getItem('usageSent');
    let usageSent = usageSentValue != null ? JSON.parse(usageSentValue) : false;
    return usageSent;
}
export function setLogUsageStorage(usageSent: boolean) {
    localStorage.setItem('usageSent', JSON.stringify(usageSent));
}

//admincompany
export function getCompanyStorage(): any {
    if (localStorage.getItem('admincompany') != null) {
        let company = JSON.parse(localStorage.getItem('admincompany'))
        return company;
    }
    return null;
}
export function setCompanyStorage(company: any) {
    localStorage.setItem('admincompany', JSON.stringify(company));
    JSON.parse(localStorage.getItem('admincompany')!);
}
//user
export function setUserStorage(userData: any) {
    if (userData)
        localStorage.setItem('user', JSON.stringify(userData));
    else
        localStorage.setItem('user', 'null');
}
export function getUserStorage(): any {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user;
}
//version
export function setVersionStorage(version: string) {
    localStorage.setItem('appVersion', version);
}
export function getVersionStorage(): any {
    return localStorage.getItem('appVersion');
}
//
export function removeUserStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('admincompany');
    localStorage.removeItem('welcome');
    localStorage.removeItem('profile');
    localStorage.removeItem('usageSent');
}