import { DayOffStatus } from "src/app/core/enums/day-off-status-type";
import { DayOffType } from "src/app/core/enums/day-off-type-type";

export interface EmployeeRequestListModel {
  id: string;
  name: string;
  year: number;
}


export class RequestDaysOffModel {
  id?: string;
  year: number;
  days: DayOffModel[];
  type: DayOffType;
  description?: string
  createdOn: Date;

  static getDates(days: DayOffModel[]): string[] {
    let dates: string[] = [];
    days.forEach(d => {
      d.dates.forEach(date => {
        dates.push(date);
      });
    });
    return dates;
  }


}

export interface DayOffModel {
  id?: string
  status?: DayOffStatus;
  dates: string[];

}