// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  api: 'https://dev.api.yobing.app',
  storage:
  {
    basicUrl: 'https://yobingdev.blob.core.windows.net',
    companies: "companies",
  },
  firebase: {
    apiKey: "AIzaSyC1H1Yv2SvcicOOsX6VXSyhj2apVJip3vI",
    authDomain: "yobing-dev.firebaseapp.com",
    projectId: "yobing-dev",
    storageBucket: "yobing-dev.appspot.com",
    messagingSenderId: "599015301336",
    appId: "1:599015301336:web:ad7006ffc1558a553bb7ad"
  },
  firebaseCollections: {
    companies: "companies",
    notifications: "notifications",
    faqs: "faqs",
  },
  useEmulators: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
