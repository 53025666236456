import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { getCompanyStorage } from 'src/app/core/functions-localStorage';

@Injectable()
export class AppBreadcrumbService {
    constructor(private router: Router) { }
    private itemsSource = new Subject<MenuItem[]>();

    itemsHandler = this.itemsSource.asObservable();

    setItems(items: MenuItem[]) {
        const currentRoute = this.router.url;
        const company = getCompanyStorage();
        if (company !== null
            && !currentRoute.includes("/tenants") && !currentRoute.includes("/versions")
        ) {
            items.unshift({
                label: `(${company.tradeName})`,
                routerLink: ['/tenants']
            })
        }
        this.itemsSource.next(items);
    }
}
