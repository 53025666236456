import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import * as LogRocket from 'logrocket';

@Injectable({
    providedIn: 'root'
})
export class LogRocketService {

    user: any;

    constructor(private authService: AuthService) { }

    init() {
        LogRocket.init('zten9a/yobing-rkwcf');
    }

    setUserId(userData) {
        if (userData) {
            LogRocket.identify(userData.claims.sub, {
                name: userData.claims.name,
                email: userData.claims.email,
                roles: userData.claims.role
            });
        }
    }
}
