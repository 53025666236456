import { Injectable } from '@angular/core';
import { SharedSytleService } from './shared/shared-style.service';



@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    constructor(private sharedSytleService: SharedSytleService) {
    }

    init() {
        var hostname = window.location.hostname;
        this.loadData(hostname);
    }

    loadData(hostname: string) {
        if (hostname.indexOf('yobing') < 0 && hostname.indexOf('localhost') < 0) {
            this.sharedSytleService.setLogo('assets/layout/images/test/classora-');
            this.sharedSytleService.setTitle("Classora People - Panel");
            document.getElementById('appIcon').setAttribute('href', 'assets/layout/images/test/classora-favicon.ico');
        }
        else {
            this.sharedSytleService.setDefaultValues();
        }
    }




}
