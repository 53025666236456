import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EmployeeStatusModel } from "./employee-status.model";

@Injectable()
export class EmployeeStatusService {
  constructor(private http: HttpClient) { }

  private buildUrl(id?: string): string {
    return `${environment.api}/employee-status`
  }

  public getStatus(): Observable<EmployeeStatusModel> {
    return this.http.get<EmployeeStatusModel>(this.buildUrl());
  }


}
