
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable()

export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: Error) {

        // Verifica si el error es un error de carga de chunk
        if (error.message.includes('Loading chunk') && error.message.includes('failed')) {

            // Forzar recarga de la página
            window.location.reload();

        } else {
            // Maneja otros tipos de errores globalmente aquí
            if (!environment.production)
                console.error('Error global detectado:', error);

        }

    }

}



