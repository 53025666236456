export enum NotificationType {
  Request = "request",
}

export class NotificationTypeType {
  label: string;
  value: NotificationType;
  static types: NotificationTypeType[] = [
    { value: NotificationType.Request, label: 'Solicitud' }
  ]
  static mapType(type: NotificationType): string {
    return this.types.filter(t => t.value == type).map<string>(f => f.label)[0];
  }

}
