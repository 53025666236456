import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Subscription } from "rxjs";
import { SharedUserDataService } from "src/app/shared/shared-user-data.service";

@Component({
    templateUrl: './welcome.component.html',
    selector: 'app-welcome'
})

export class WelcomeComponent implements OnInit {
    welcomeDialog: boolean;
    isAdmin: boolean;
    loadingSpinner: boolean = true;
    private subsAfAuth: Subscription;
    private subsWelcomeMessage: Subscription;
    constructor(public afAuth: AngularFireAuth,
        public sharedUserDataService: SharedUserDataService
    ) {
    }

    ngOnDestroy(): void {
        this.subsAfAuth?.unsubscribe();
        this.subsWelcomeMessage?.unsubscribe();
    }
    ngOnInit(): void {
        this.welcomeDialog = this.sharedUserDataService.getWelcomeMessageValue();
        this.subsWelcomeMessage = this.sharedUserDataService.getWelcomeMessage()
            .subscribe(show => {
                this.welcomeDialog = show;
            });
    }

    loadedStatus(event) {
        this.loadingSpinner = false;
    }
    statusChange(event) {
        this.sharedUserDataService.setWelcomeMessage(false);
        this.cancel();
    }

    cancel() {
        this.sharedUserDataService.setWelcomeMessage(false);
        this.welcomeDialog = false;
    }
}