import { Permission } from "./permissions";

export enum Profile {
  Admin = 'admin',
  Employee = 'employee',
  CompanyManager = 'company-manager',
  WorkplaceManager = 'workplace-manager'
}

export class ProfileType {
  label: string;
  value: Profile;
  static types: ProfileType[] = [
    { value: Profile.Admin, label: 'Administrador' },
    { value: Profile.CompanyManager, label: 'Responsable' },
    { value: Profile.WorkplaceManager, label: 'Responsable' },
    { value: Profile.Employee, label: 'Empleado' },

  ]
  static mapType(status: Profile): string {
    return this.types.filter(t => t.value == status).map<string>(f => f.label)[0];
  }
  static getFilterTypes(roles: Permission[]): ProfileType[] {
    if (roles.includes(Permission.CompanyManager))
      return this.types.filter(t => t.value != Profile.Admin && t.value != Profile.WorkplaceManager);
    if (roles.includes(Permission.WorkplaceManager))
      return this.types.filter(t => t.value != Profile.Admin && t.value != Profile.CompanyManager);
  }
}
