import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { Permission } from './core/enums/permissions';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
    canLoad: [AuthGuard],
    data: { redirectTo: '/dashboard' },
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
    canLoad: [AuthGuard],
    data: { redirectTo: '/dashboard' },
  },
  {
    path: 'dashboard',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/dashboard/dashboard.module')
        .then(m => m.DashboardModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'faq',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/faq/faq.module')
        .then(m => m.FaqModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'company',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/company/company.module')
        .then(m => m.CompanyModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'workplaces',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/workplaces/workplaces.module')
        .then(m => m.WorkplacesModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'work-schedules',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/work-schedules/work-schedules.module')
        .then(m => m.WorkSchedulesModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'employees',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/employees/employees.module')
        .then(m => m.EmployeesModule),
    canLoad: [AuthGuard]
  }
  ,
  {
    path: 'registers',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/employees-registers/employees-registers.module')
        .then(m => m.EmployeesRegistersModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'calendars',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/calendars/calendars.module')
        .then(m => m.CalendarsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'registers-company',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/admin-company-events/company-events.module')
        .then(m => m.CompanyEventsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'my-registers',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/employee-registers/employee-registers.module')
        .then(m => m.EmployeeRegistersModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'my-calendars',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/employee-calendars/employee-calendars.module')
        .then(m => m.EmployeeCalendarsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'my-requests',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/employee-requests/employee-requests.module')
        .then(m => m.EmployeeRequestsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'requests',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/employees-requests/employees-requests.module')
        .then(m => m.EmployeesRequestsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reports',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/reports/reports.module')
        .then(m => m.ReportsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'days-off',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/days-off-configuration/days-off-configuration.module')
        .then(m => m.DaysOffConfigurationModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'account',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/account/account.module')
        .then(m => m.AccountModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'prueba',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/prueba/prueba.module')
        .then(m => m.PruebaModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'logs',
    component: AppMainComponent,
    canLoad: [AuthGuard],
    data: {
      permissions: {
        only: [Permission.Admin],
        redirectTo: '/access'
      }
    },
    loadChildren: () => import('src/app/pages/app-usage/app-usage.module')
      .then(m => m.AppUsageModule)
  },
  {
    path: 'tenants',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/admin-companies/admin-companies.module')
        .then(m => m.AdminCompaniesModule),
    canLoad: [AuthGuard],
    data: {
      permissions: {
        only: [Permission.Admin],
        redirectTo: '/access'
      }
    }
  },

  {
    path: 'versions',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/versions/versions.module')
        .then(m => m.VersionsModule),
    canLoad: [AuthGuard],
    data: {
      permissions: {
        only: [Permission.Admin],
        redirectTo: '/access'
      }
    }
  },
  {
    path: 'error',
    component: AppErrorComponent
  },
  { path: 'access', component: AppAccessdeniedComponent },
  { path: 'notfound', component: AppNotfoundComponent },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
