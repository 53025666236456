export enum Theme {
  Dark = "dark",
  Light = "light"
}

export class ThemeType {
  label: string;
  value: Theme;
  static types: ThemeType[] = [
    { value: Theme.Dark, label: 'Dark' },
    { value: Theme.Light, label: 'Light' },

  ]
  static mapType(theme: Theme): string {
    return this.types.filter(t => t.value == theme).map<string>(f => f.label)[0];
  }
}
