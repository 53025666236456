<div class="flex align-items-center">
  <i *ngIf="notifications && notifications.length>0" class="pi pi-bell mr-3 cursor-pointer" style="font-size: 1.4rem"
    pBadge [value]="notifications.length" (click)="menuNotifications.toggle($event)"></i>
  <p-avatar *ngIf="avatar" class="mr-2" shape="circle" image="{{avatar}}">
  </p-avatar>
  <p-avatar *ngIf="!avatar" class="mr-2" shape="circle" label="{{initials}}">
  </p-avatar>

</div> <p-menu #menuNotifications [model]="itemsNotifications" [popup]="true" (onHide)="onHideMenu($event)"
  appendTo="body"></p-menu>
<span *ngIf="userName && (!isMobile() || isOverlay() || isHorizontal())" class="mr-2 mt-2">
  {{userName}}</span>