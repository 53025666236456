import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ProfileType } from '../core/enums/profile';
import { setProfileStorage } from '../core/functions-localStorage';
@Injectable()
export class ProfileService {
    private subjProfile = new Subject<ProfileType>();
    constructor() { }

    public getProfile(): Observable<ProfileType> {
        return this.subjProfile.asObservable();
    }

    public updateProfile(profile: ProfileType): void {
        setProfileStorage(profile);
        this.subjProfile.next(profile);
    }
}